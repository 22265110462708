<template>
<div>
  <v-dialog v-model="edit_card_dialog" max-width="600px">
    <v-card class="pa-5">
      <v-card-title
        class="d-flex justify-space-between pa-0 mb-10"
      >
        <h4>{{$t('editCard.editCreditCard')}}</h4>
        <v-icon @click="edit_card_dialog = false"
          >mdi-close</v-icon
        >
      </v-card-title>
      <v-card-text class="pa-0">
        <v-form
          ref="edit_card_form"
          class="mb-5"
          v-model="edit_card_valid"
          lazy-validation
          v-on:submit.prevent
        >
          <v-row class="mt-0">
            <v-col cols="12" sm="12" class="py-0">
              <label class="black--text mb-3 d-flex"
                >{{$t('editCard.cardNumber')}}</label
              >
              <v-text-field
                label="Card Number"
                placeholder="Card Number"
                v-model="edit_number"
                required
                outlined
                dense
                :rules="numberRules"
                disabled
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col cols="12" sm="6" class="py-0">
              <label class="black--text mb-3 d-flex"
                >{{$t('editCard.expiryDate')}}</label
              >
              <v-text-field
                label="MM/YY"
                placeholder="MM/YYYY"
                v-model="edit_expiry"
                required
                outlined
                dense
                :rules="expiryRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="py-0">
              <label class="black--text mb-3 d-flex"
                >{{$t('editCard.cvv')}}</label
              >
              <v-text-field
                label="CVV"
                placeholder="CVV"
                v-model="edit_cvv"
                required
                outlined
                dense
                :rules="expiryCVV"
                disabled
              ></v-text-field>
            </v-col>
          </v-row>
          <v-checkbox
            v-model="edit_primary"
            label="Make this card as Primary payment source"
            color="success"
            hide-details
            class="mt-1"
          ></v-checkbox>
          <v-row class="mt-3">
            <v-col cols="12" sm="5" class="py-0">
              <v-btn
                color="success"
                class="mr-3"
                depressed
                @click="editCustCard"
                :disabled="!edit_card_valid"
                >{{$t('editCard.saveCard')}}</v-btn
              >
            </v-col>
            <p> {{$t('editCard.updateExpiryPara')}}</p>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
  <LoadingAlert
    :loading="loadingAlertFlag"
    :loadingText="loadingAlertText"
  />
  <InfoAlert
    :InfoAlert="infoAlertFlag"
    :InfoAlertTitle="infoAlertTitle"
    :InfoAlertMessage="infoAlertMessage"
    @emitInfoAlertOkClicked="clearAddModels"
  />
  </div>
</template>

<script>
import Vue from 'vue';
import LoadingAlert from '../LoadingAlert';
import InfoAlert from '../InfoAlert'
import {
  UPDATE_CARD,
} from '../../utils/apptweakqueries';

export default Vue.extend({
  name: 'EditCard',
  components: {
    LoadingAlert,
    InfoAlert,
  },
  data() {
    return {
      edit_number: '',
      edit_cvv: null,
      edit_year: '',
      edit_month: '',
      edit_expiry: '',
      edit_primary: false,
      edit_card_dialog: false,
      edit_card_valid: false,
      //enableEdit: false,
      numberRules: [
        (v) => !!v || 'Card Number is required',
        (v) =>
          (v && v.length == 16) ||
          'Card Number must be numerical and 16 digits long',
        (v) =>
          /[0-9]{16}/.test(v) ||
          'Card Number must be numerical 16 and digits long',
      ],
      expiryRules: [
        (v) => !!v || 'Expiry Month and Year is required',
        (v) =>
          (v && v.length == 7) || 'Expiry date must be MM/YYYY - ex. 07/2023',
        (v) =>
          /(0[1-9]|1[0-2])\/([0-9]{4})/.test(v) ||
          'Expiry date must be MM/YYYY - ex. 07/2023',
      ],
      expiryCVV: [
        (v) => !!v || 'Card CVV is required',
        (v) =>
          (v && v.length == 3) ||
          'Card CVV must be numerical and 3 digits long',
        (v) =>
          /[0-9]{3}/.test(v) || 'Card CVV must be numerical and 3 digits long',
      ],

      loadingAlertFlag: false,
      loadingAlertText: 'Validating...',

      infoAlertFlag: false,
      infoAlertTitle: 'Info',
      infoAlertMessage: '',
    }
  },
  props: {
    editCardData: {
      type: Object,
      default() {
        return;
      },
    },
  },
  methods: {
    editCustCard () {
      console.log('editCustCard');
      //this.$refs.edit_card_form.validate();
      if (!this.edit_number || !this.edit_expiry || !this.edit_cvv) {
        return;
      }
      if (!this.edit_card_valid) {
        return;
      }
      this.loadingAlertFlag = true;
      let splitExpiry = this.edit_expiry.split('/');
      this.$apollo
      .query({
        query: UPDATE_CARD,
        variables: {
          cardId: this.edit_id,
          month: parseInt(splitExpiry[0]),
          year: parseInt(splitExpiry[1]),
        },
      })
      .then((res) => {
        console.log(res.data);
        this.loadingAlertFlag = false;
        this.edit_card_dialog = false;
        this.infoAlertFlag = true;
        this.infoAlertTitle = 'Success!!!';
        this.infoAlertMessage = 'Card updated successfully';
      })
      .catch((error) => {
        console.log(error);
      });
    },
    clearAddModels() {
      console.log('clearAddModels');
      this.infoAlertFlag = false;
    },
  },
  watch: {
    editCardData: function (data) {
      this.edit_card_dialog = true;
      console.log(data);
      this.edit_id = data.number;
      this.edit_number = data.number;
      this.edit_cvv = data.cvv;
      this.edit_expiry = data.expiry;
    },
  },
  mounted() {
    this.edit_card_valid = true;
    //this.enableEdit = true;
  },
})
</script>
