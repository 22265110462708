<template>
  <div class="text-center">
    <v-dialog
      v-model="InfoAlert"
      persistent
      width="300"
    >
      <v-card>
        <v-card-title class="headline grey lighten-2">
          {{ InfoAlertTitle }}
        </v-card-title>

        <v-card-text>
          {{ InfoAlertMessage }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="emitInfoAlertOkClicked"
          >
            {{$t('infoAlert.ok')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name : 'InfoAlert',
  data() {
    return {
      emitMsg : 'click Success'
    }
  },
  props:{
    InfoAlert : {
      type: Boolean,
      default: false
    },
    InfoAlertTitle : {
      type: String,
      default: 'Info...'
    },
    InfoAlertMessage : {
      type: String,
      default: 'Redirecting...'
    },
  },
  methods: {
    emitInfoAlertOkClicked() {
      //console.log('emitInfoAlertOkClicked');
      this.$emit('emitInfoAlertOkClicked', this.emitMsg);
    }
  }
})
</script>

