<template>
  <div class="text-center">
      <v-dialog
        v-model="loading"
        hide-overlay
        persistent
        width="300"
      >
        <v-card
        >
          <v-card-title class="headline grey lighten-2">
            {{ loadingText }}
          </v-card-title>
          <v-card-text
            class="cust"
          >
            <v-progress-linear
              indeterminate
              color="primary"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name : 'LoadingAlert',
  data() {
    return {
    }
  },
  props:{
    loading : {
      type: Boolean,
      default: false
    },
    loadingText : {
      type: String,
      default: 'Processing...'
    },
  }
})
</script>

